<template>
  <div>
    <side-form
      :is-add-new-page-sidebar-active.sync="isShowFormAdd"
      :is-loading="isLoading"
      :type-form="typeForm"
      :data="warehouseData"
      @onSubmit="onSubmit"
    />
    <b-card>
      <own-table
        ref="receiveTable"
        name-table="receive"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        :export-excel="false"
        @searchFieldOnChange="searchOnChange"
        @evt-add="showAddForm"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(document_number)="{ data }">
          <b-link
            :to="{ name: 'apps-penerimaan-view', params: { id: data.item.id } }"
          >
            {{ data.value }}</b-link
          >
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(is_default)="{ data }">
          <b-badge pill :variant="data.item.is_default ? 'success' : 'danger'">
            <feather-icon
              :icon="data.item.is_default ? 'CheckIcon' : 'XIcon'"
            />
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="edit(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem, BLink } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import SideForm from './components/SideForm.vue'
import penerimaanStoreModule from './penerimaanStoreModule'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    SideForm,
    BLink,
  },
  setup() {
    const RECEIVE_STORE_MODULE = 'receive'
    if (!store.hasModule(RECEIVE_STORE_MODULE)) {
      store.registerModule(RECEIVE_STORE_MODULE, penerimaanStoreModule)
    }
    onUnmounted(() => {
      store.unregisterModule(RECEIVE_STORE_MODULE)
    })
    const receiveTable = ref(null)
    const columns = ref([
      {
        label: 'No Terima',
        key: 'document_number',
      },
      {
        label: 'Tgl. Terima',
        key: 'received_date',
        sortable: true,
      },
      {
        label: 'Warehouse',
        key: 'warehouse.warehouse_name',
        sortable: true,
      },
      {
        label: 'Keterangan',
        key: 'note',
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const warehouseData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      receiveTable.value.submit(formData, isSuccess => {
        if (isSuccess) {
          isShowFormAdd.value = false
        }
        isLoading.value = false
      })
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      warehouseData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const edit = data => {
      typeForm.value = 'edit'
      receiveTable.value.onEdit()
      warehouseData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      receiveTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)
    return {
      columns,
      queryFilter,
      receiveTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      edit,
      warehouseData,
      typeForm,
      onDelete,
    }
  },
}
</script>
